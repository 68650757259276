// delete Sede button js

$('.sede-delete').on('click', function () {
    $(this).hide();
    alert("si è sicuri di volere eliminare la sede selezionata? chiudere questo alert e premere il tasto rosso.")
    $(this).next().show();

});

$('.user-delete').on('click', function () {
    $(this).hide();
    alert("si è sicuri di volere eliminare l'utente selezionato? chiudere questo alert e premere il triangolo rosso.")
    $(this).next().children().show();

});



$( "#sidebarBtnOff" ).on('click', function() {
    $( "#sidebar" ).fadeOut( "slow", function() {
      $('#contentArea').removeClass('col-md-10').addClass('col-md-12');
    });
    $(this).hide();
    $( "#sidebarBtnOn" ).show();
  });

  $( "#sidebarBtnOn" ).on('click', function() {
    $( "#sidebar" ).fadeIn( "slow", function() {
      $('#contentArea').removeClass('col-md-12').addClass('col-md-10');
    });
    $(this).hide();
    $( "#sidebarBtnOff" ).show();
  });