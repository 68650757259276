
$(function cerDescription() {

    $('#cer_id').on('change', function () {

        var descrizione = $(this).find(':selected').data('descrizione');

        var target = $('#cer_descrizione')
        target.val(descrizione);

    });
});


$(function gestioneDestinoRifiuto() {

    $('#radio_recupero').on('click', function () {

        if ($(this).is(':checked')) {
            $('#codice_destino_recupero').removeAttr('disabled');
            $('#codice_destino_smaltimento').attr('disabled', true);
        }
    });

    $('#radio_smaltimento').on('click', function () {

        if ($(this).is(':checked')) {

            $('#codice_destino_smaltimento').removeAttr('disabled');
            $('#codice_destino_recupero').attr('disabled', true);
        }
    });

});

$(function getSediGroup() {

    var route = $('#anagrafica_produttore_id').data('route');

    $('#anagrafica_produttore_id').on('change', function () {
        var target = $('#sede_produzione_id');
        var anagraficaId = $(this).find(":selected").data('produttore');
        getSedi(anagraficaId, route, target)
    });

    $('#anagrafica_trasportatore_id').on('change', function () {
        var target = $('#sede_trasportatore_id');
        var anagraficaId = $(this).find(":selected").data('trasportatore');
        getSedi(anagraficaId, route, target)
    });

    $('#anagrafica_destinatario_id').on('change', function () {
        var target = $('#sede_destinatario_id');
        var anagraficaId = $(this).find(":selected").data('destinatario');
        getSedi(anagraficaId, route, target)
    });


});

function getSedi(id, route, target) {
    $.ajax({

        'url': route,
        'type': 'GET',
        'data': {
            'anagrafica_id': id
        },
        'success': function (data) {
            var dataDecode = JSON.parse(data);
            console.log(dataDecode);
            target.find('option').remove();
            dataDecode.forEach(element => {
                target.append('<option value="' + element.id + '">' + element.toponimo + ' ' + element.indirizzo + ', ' + element.numero_civico + '. ' + element.comune + '(' + element.provincia + ') </option>')
            });
        },
        'error': function (error) {
            console.log(error);
        }
    });
}

$(function deleteMovimento() {

    $(document).on('click', '.delete-movimento-btn', function () {
        if (confirm('Sei sicuro di voler eliminare questo movimento?')) {
            $.ajax({

                'url': $(this).data('route'),
                'type': 'DELETE',
                'headers': {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                'data': {
                    'movimento_id': $(this).attr('id'),
                },
                'success': function (data) {
                    location.reload();
                },
                'error': function (error) {
                    console.log(error);
                }
            });
        }

    });

});

$(function printRegistroIntermediazione() {

    $('#registro_print_confirm').on('click', function () {
        const $btn = $(this);
        const oldTxt = $btn.text();
        $btn.prop('disabled', true).text('...attendere...');

        $.ajax({

            'url': $(this).data('route'),
            'type': 'POST',
            'headers': {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            'data': {
                'data_stampa': $('#data_stampa').val(),
            },
            'success': function (data) {
                if (data.success) {
                    $('#printModal').modal('hide');
                    $('#movimenti-intermediazione-datatable').DataTable().ajax.reload();
                    
                    const countUpd = data.count;
                    alert(`Sono stati stampati e aggiornati correttamente ${countUpd} movimenti`);
                } else {
                    $('#printModal-body').append('Si è verificato un errore nel salvataggio dei movimenti:' + JSON.stringify(data));
                    setTimeout(function () {
                        $('#printModal').modal('hide');
                    }, 3000);
                }

                $btn.prop('disabled', false).text(oldTxt);
            },
            'error': function (error) {
                alert("Si è verificato un errore: " + JSON.stringify(error));
                console.log(error);

                $btn.prop('disabled', false).text(oldTxt);
            }
        });

    });

});