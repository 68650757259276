$(document).ready(function () {


    triggerCerKgChart(anno = null)

    function triggerCerKgChart(anno) {
        if ($('#cerKgChart').length) {
            if (!anno) {
                anno = new Date().getFullYear();
            }
            $.ajax({
                url: $('#cerKgChart').data('url'),
            }).done(function (data) {
                console.log(data);
                if (!data[anno]) {
                    $('#cerKgChart').hide();
                    $('#cerKgChart').empty();
                    $('#cerKgChart-error-msg').show();
                } else {
                    console.log(data[anno]);
                    $('#cerKgChart').show();
                    $('#cerKgChart').empty();
                    $('#cerKgChart-error-msg').hide();
                    var options = {
                        series: [{
                            name: 'Kg',
                            data: data[anno].series
                        }],
                        colors : ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#F86624', '#F9A3A4', '#1B998B'],
                        chart: {
                            type: 'bar',
                            height: 350,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                                borderRadius: 4
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: data[anno].categories,
                        }
                    };

                    var chart = new ApexCharts(document.querySelector("#cerKgChart"), options);
                    chart.render();
                }
            });
        }
    }

    $('#select-anno-cerKg-Chart').on('change', function () {
        var anno = $('#select-anno-cerKg-Chart').val();
        triggerCerKgChart(anno);
    })
});


