$(document).ready(function () {

    triggerMovimentiGiornoChart(anno = null, mese = null)

    function triggerMovimentiGiornoChart(anno, mese) {
        if ($('#movimentiGiornoChart').length) {
            if (!anno) {
                anno = new Date().getFullYear();
            }
            $.ajax({
                url: $('#movimentiGiornoChart').data('url'),
            }).done(function (data) {
                console.log('data', data);
                if (!data[anno]) {
                    $('#movimentiGiornoChart').hide();
                    $('#movimentiGiornoChart').empty();
                    $('#movimentiGiornoChart-error-msg').show();
                } else {
                    console.log('mese',mese);
                    console.log('A/M', data[anno]);
                    $('#movimentiGiornoChart').show();
                    $('#movimentiGiornoChart').empty();
                    $('#movimentiGiornoChart-error-msg').hide();
                    var options = {
                        series: data[anno].series,
                        colors : ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#F86624', '#F9A3A4', '#1B998B'],
                        chart: {
                            defaultLocale: 'it',
                            locales: [{
                              name: 'it',
                              options: {
                                months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
                                shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dec'],
                                days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
                                shortDays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
                                toolbar: {
                                  download: 'Download SVG',
                                  selection: 'Selezione',
                                  selectionZoom: 'Selezione Zoom',
                                  zoomIn: 'Ingrandisci',
                                  zoomOut: 'Rimpicciolisci',
                                  pan: 'Panning',
                                  reset: 'Resetta Zoom',
                                }
                              }
                            }],
                            height: 350,
                            type: 'scatter',
                            zoom: {
                                type: 'xy'
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        grid: {
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: true
                                }
                            },
                        },
                        xaxis: {
                            type: 'datetime',
                        },
                        // yaxis: {
                        //     max: 70
                        // }
                    };

                    var chart = new ApexCharts(document.querySelector("#movimentiGiornoChart"), options);
                    chart.render();
                }
            });
        }
    }

    $('#select-anno-movimentiGiorno-Chart').on('change', function () {
        var anno = $('#select-anno-movimentiGiorno-Chart').val();
        if (anno.length) {
            triggerMovimentiGiornoChart(anno);
        }
    })

    $('#select-mese-movimentiGiorno-Chart').on('change', function () {
        var anno = $('#select-anno-movimentiGiorno-Chart').val();
        if (anno.length) {
            triggerMovimentiGiornoChart(anno);
        }
    })

});



