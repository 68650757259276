$(document).ready(function () {


    triggerCerChart(anno = null)

    function triggerCerChart(anno) {
        if ($('#cerCountChart').length) {
            if (!anno) {
                anno = new Date().getFullYear();
            }
            $.ajax({
                url: $('#cerCountChart').data('url'),
            }).done(function (data) {
                console.log(data);
                if (!data[anno]) {
                    $('#cerCountChart').hide();
                    $('#cerCountChart').empty();
                    $('#cerCountChart-error-msg').show();
                } else {
                    console.log(data[anno]);
                    $('#cerCountChart').show();
                    $('#cerCountChart').empty();
                    $('#cerCountChart-error-msg').hide();
                    var options = {
                        series: [{
                            name: 'N. movimenti',
                            data: data[anno].series,
                        }],
                        colors : ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#F86624', '#F9A3A4', '#1B998B'],
                        chart: {
                            type: 'bar',
                            height: 350,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                                borderRadius: 4
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: data[anno].categories,
                        }
                    };

                    var chart = new ApexCharts(document.querySelector("#cerCountChart"), options);
                    chart.render();
                }
            });
        }
    }

    $('#select-anno-cerCount-Chart').on('change', function () {
        var anno = $('#select-anno-cerCount-Chart').val();
        triggerCerChart(anno);
    })
});


