$(function anagraficheDataTable() {

    var table = $('#anagrafiche-datatable').DataTable({
        dom: "Bfrtlip",
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "Tutte"]],
        processing: true,
        serverSide: true,
        ajax: $('#anagrafiche-datatable').data('url'),
        columns: [
            { data: 'ragione_sociale', name: 'ragione_sociale' },
            { data: 'partita_iva', name: 'partita_iva' },
            { data: 'codice_fiscale', name: 'codice_fiscale' },
            { data: 'email', name: 'email' },
            { data: 'telefono', name: 'telefono' },
            { data: 'tipologie_registro', name: 'tipologie_registro' },
            { data: 'ruoli_anagrafica', name: 'ruoli_anagrafica' },
            { data: 'iscrizione_albo_nr', name: 'iscrizione_albo_nr' },
            { data: 'importato_da', name: 'importato_da' },
            { data: 'stato', name: 'stato' },
            { data: 'totMovimenti', name: 'totMovimenti' },
            {
                data: 'azioni',
                name: 'azioni',
                orderable: true,
                searchable: true
            },
        ],
        buttons: [
            {
                extend: 'copy',
                text: 'Copia',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'excel',
                text: 'Excel',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'pdf',
                text: 'PDF',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
        ],
        responsive: true,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
        }
    });

});


$(function movimentiIntermediazioneDataTable() {

    var table = $('#movimenti-intermediazione-datatable').DataTable({
        dom: "Bfrtlip",
        searching: false,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "Tutte"]],
        processing: true,
        serverSide: true,
        ajax: $('#movimenti-intermediazione-datatable').data('url'),
        columns: [
            {
                data: 'numero', name: 'numero', searchable: true, orderable: true
            },
            { data: 'ordine_id', name: 'ordine_id' },
            { data: 'tipologia', name: 'tipologia' },
            { data: 'data_movimento', name: 'data_movimento' },
            { data: 'cer_id', name: 'cer_id' },
            { data: 'stampato_alle', name: 'stampato_alle' },
            { data: 'formulario_nr', name: 'formulario_nr' },
            { data: 'formulario_data', name: 'formulario_data' },
            { data: 'destinato_a', name: 'destinato_a' },
            { data: 'codice_destino', name: 'codice_destino' },
            { data: 'kg', name: 'kg' },
            { data: 'stato_registro', name: 'stato_registro' },
            { data: 'produttore_json', name: 'produttore_json' },
            { data: 'trasportatore_json', name: 'trasportatore_json' },
            { data: 'destinatario_json', name: 'destinatario_json' },
            {
                data: 'azioni',
                name: 'azioni',
                orderable: true,
                searchable: true
            },
        ],
        buttons: [
            {
                extend: 'copy',
                text: 'Copia',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'excel',
                text: 'Excel',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'pdf',
                text: 'PDF',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
        ],
        responsive: true,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
        }
    });




});

// $(function googleChartMeseCer() {

//     google.charts.load('current', { 'packages': ['bar'] });
//     google.charts.setOnLoadCallback(drawChart);

//     function drawChart() {

//         $.ajax({
//             'url': $('#reportMeseCer').data('route'),
//             'type': 'GET',
//             'success': function (elements) {
//                 console.log(elements);

//                 var data = google.visualization.arrayToDataTable(elements);
//                 var options = {
//                     width: 1000,
//                     height: 500,
//                     title: 'Report per Anno-cer',
//                     legend: { position: 'bottom' }
//                 };

//                 var chart = new google.charts.Bar(document.getElementById('reportMeseCer'));

//                 chart.draw(data, google.charts.Bar.convertOptions(options));

//             },
//             'error': function (error) {
//                 console.log(error);
//             }
//         });
//     }

//     $('#btn-search-data').on('click', function () {

//         $('#reportMeseCer').empty();

//         var anno = $('#anno').val();
//         var cer = $('#codice_cer').val();

//         google.charts.load('current', { 'packages': ['line'] });
//         google.charts.setOnLoadCallback(drawChart);

//         function drawChart() {

//             $.ajax({
//                 'url': $('#reportMeseCer').data('route'),
//                 'type': 'GET',
//                 'data': {
//                     'anno': anno,
//                     'cer': cer,
//                 },
//                 'success': function (elements) {
//                     console.log(elements);
//                     google.charts.load('current', { 'packages': ['bar'] });
//                     google.charts.setOnLoadCallback(drawChart);

//                     function drawChart() {
//                         var data = google.visualization.arrayToDataTable(elements);
//                         var options = {
//                             width: 1000,
//                             height: 500,
//                             title: 'Report per Anno-cer',
//                             legend: { position: 'bottom' }
//                         };

//                         var chart = new google.charts.Bar(document.getElementById('reportMeseCer'));

//                         chart.draw(data, google.charts.Bar.convertOptions(options));
//                     }

//                 },
//                 'error': function (error) {
//                     console.log(error);
//                 }
//             });
//         }

//     })


//     google.charts.setOnLoadCallback(drawChartBis);

//     function drawChartBis() {
//         console.log('inBis');
//         $.ajax({
//             'url': $('#reportMeseCer').data('route'),
//             'type': 'GET',
//             'success': function (elements) {
//                 console.log(elements);

//                 var data = google.visualization.arrayToDataTable(elements);
//                 var options = {
//                     width: 600,
//                     height: 500,
//                     title: 'another graph',
//                     legend: { position: 'top' }
//                 };

//                 var chart = new google.charts.Bar(document.getElementById('MovCodFisc'));

//                 chart.draw(data, google.charts.Bar.convertOptions(options));

//             },
//             'error': function (error) {
//                 console.log(error);
//             }
//         });
//     }
// });
