/* Add .mt-istat to sectin which needs to be enhanced */

class MTISTATEnhancer {
    baseEl;
    baseUrl = '';
    regioni = {};
    province = {};
    comuni = {};
    provinceByRegione = {};
    comuniByProvincia = {};

    constructor(el) {
        this.baseEl = $(el);
        const istatUrlElement = document.head.querySelector('meta[name="api-istat-base-url"]');
        this.baseUrl = istatUrlElement ? istatUrlElement.content : '';
        if (!this.baseUrl) {
            console.error('MTISTATEnhancer: missing meta api-istat-base-url');
            return;
        }
        console.log('ISTAT BaseUrl:', this.baseUrl);
        this.init();
    }

    async init() {
        this.regioni = await this.getRegioni();
        this.provinceByRegione = await this.getProvinceByRegione();
        this.comuniByProvincia = await this.getComuniByProvincia();

        // console.log(this.regioni, this.provinceByRegione, this.comuniByProvincia);
        this.initFields();
    }

    async getRegioni() {
        const regioniJSON = await $.getJSON(`${this.baseUrl}/regions`);
        const regioni = {};
        regioniJSON.forEach(regione => {
            regioni[regione.id] = regione;
        });

        return regioni;
    }

    async getProvinceByRegione() {
        const provinceJSON = await $.getJSON(`${this.baseUrl}/provinces`);
        const province = {};
        provinceJSON.forEach(provincia => {
            if (!province[provincia.id_regione]) {
                province[provincia.id_regione] = [];
            }
            province[provincia.id_regione].push(provincia);
            // global list of province
            this.province[provincia.id] = provincia;
        });

        return province;
    }

    async getComuniByProvincia() {
        const comuniJSON = await $.getJSON(`${this.baseUrl}/cities`);
        const comuni = {};
        comuniJSON.forEach(comune => {
            if (!comuni[comune.id_provincia]) {
                comuni[comune.id_provincia] = [];
            }
            comuni[comune.id_provincia].push(comune);
            // global list of comuni
            this.comuni[comune.id] = comune;
        });

        return comuni;
    }

    async initFields() {
        // for each enhanced field (data-mt-istat-autofill
        this.baseEl.find('[data-mt-istat-autofill]').each((i, el) => {
            const $el = $(el);
            console.log('Field: ', $el);
            const fillType = $el.data('mt-istat-autofill');
            switch (fillType) {
                case 'regione':
                    this.fillRegioni($el);
                    break;
                case 'provincia':
                    this.fillProvince($el);
                    break;
                case 'comune':
                    this.fillComuni($el);
                    break;
                default:
                    console.error('MTISTATEnhancer: unknown autofill type:', fillType);
                    break;
            }

        });

        // fields that fill comuni to another field
        this.baseEl.find('[data-mt-istat-fill-comuni-to]').on('change', (evt) => {
            const $el = $(evt.currentTarget);
            //this field is of type province, the only field that can fill comuni
            const idProvincia = $el.val();

            const targetField = this.baseEl.find($el.data('mt-istat-fill-comuni-to'));
            targetField.each((i, el) => {
                const $el = $(el);
                this.fillComuni($el, idProvincia, true);
            });
        });

        // fields that set sigla to another field
        this.baseEl.find('[data-mt-istat-link-sigla-to]').on('change', (evt) => {
            const $el = $(evt.currentTarget);
            const targetField = this.baseEl.find($el.data('mt-istat-link-sigla-to'));
            const recordData = $el.find(':selected').data('details');
            console.log(recordData);
            targetField.val(recordData?.sigla);
        });

        // fields that set nome to another field
        this.baseEl.find('[data-mt-istat-link-nome-to]').on('change', (evt) => {
            const $el = $(evt.currentTarget);
            const targetField = this.baseEl.find($el.data('mt-istat-link-nome-to'));
            const recordData = $el.find(':selected').data('details');
            console.log(recordData);
            targetField.val(recordData?.nome);
        });

    }

    fillRegioni(el) {
    }

    fillProvince(el, idRegione = null) {
        const province = idRegione ? this.provinceByRegione[idRegione] : Object.values(this.province);
        const provinceList = province.sort((a, b) => { return a.nome.localeCompare(b.nome); });
        // console.log('lista province:', provinceList);

        const options = [
            $('<option>').val('').text('Seleziona')
        ];
        for (const provincia of provinceList) {
            const optElem = $('<option>').val(provincia.id)
                .text(`${provincia.nome} (${provincia.sigla})`)
                .data('details', provincia);
            if (provincia.sigla == el.data('selected')) {
                optElem.prop('selected', true);
            }
            options.push(optElem);
        }
        el.empty().append(options);
        // el.val(el.data('selected'));
    }

    fillComuni(el, idProvincia = null, emptyOnNull = false) {
        let comuni = [];
        if (!idProvincia) {
            if (!emptyOnNull) {
                comuni = Object.values(this.comuni);
            }
        } else {
            comuni = this.comuniByProvincia[idProvincia];
        }
        const comuniList = comuni.sort((a, b) => { return a.nome.localeCompare(b.nome); });
        // console.log('lista comuni:', comuniList);

        const options = [
            $('<option>').val('').text('Seleziona')
        ];
        for (const comune of comuniList) {
            const optElem = $('<option>').val(comune.id)
                .text(`${comune.nome}`)
                .data('details', comune);
            if (comune.nome == el.data('selected')) {
                optElem.prop('selected', true);
            }

            options.push(optElem);
        }
        el.empty().append(options);
    }

}

$('.mt-istat').each(function () {
    new MTISTATEnhancer(this);
});
