$(document).ready(function () {

// insert grafico qui 


    triggerChart(anno = null)

    function triggerChart(anno) {
        if ($('#annoMeseChart').length) {
            if (!anno) {
                anno = new Date().getFullYear();
            }
            $.ajax({
                url: $('#annoMeseChart').data('url'),
            }).done(function (data) {
                if (!data[anno]) {
                    $('#annoMeseChart').hide();
                    $('#annoMeseChart').empty();
                    $('#chart-error-msg').show();
                } else {
                    console.log(data[anno]);
                    $('#annoMeseChart').show();
                    $('#annoMeseChart').empty();
                    $('#chart-error-msg').hide();
                    var options = {
                        noData: {  
                            text: "In caricamento...",  
                            align: 'center',  
                            verticalAlign: 'middle',  
                            offsetX: 0,  
                            offsetY: 0,  
                            style: {  
                              color: "#000000",  
                              fontSize: '14px',  
                              fontFamily: "Helvetica"  
                            }  
                          },
                        series: data[anno].series,
                        colors : ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#F86624', '#F9A3A4', '#1B998B'],
                        chart: {
                            type: 'bar',
                            height: 350,
                            stacked: true,
                            toolbar: {
                                show: true
                            },
                            zoom: {
                                enabled: true
                            },
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: 'bottom',
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }],
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 10
                            },
                        },
                        xaxis: {
                            type: 'string',
                            categories: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'
                            ],
                        },
                        legend: {
                            position: 'right',
                            offsetY: 40
                        },
                        fill: {
                            opacity: 1
                        }
                    };

                    var chart = new ApexCharts(document.querySelector("#annoMeseChart"), options);
                    chart.render();
                }
            });
        }
    }

    $('#select-anno-chart').on('change', function () {
        var anno = $('#select-anno-chart').val();
        triggerChart(anno);
    })
});


