// require ('bootstrap');
require('./bootstrap');
require( 'datatables.net-dt' );
require( 'datatables.net-buttons-bs5' )();
// require( 'datatables.net-buttons/js/buttons.html5.js' )();
// require( 'datatables.net-searchpanes-bs5' )();
require('./istat');
require('./datatable/datatable');
require('./buttons/buttons');
require('./movimenti/movimenti');
require('./charts/annoMese');
require('./charts/cerCount');
require('./charts/cerKg');
require('./charts/movimentiGiorno');


var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})